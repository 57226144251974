import React from 'react';
import { Link } from 'gatsby';
import Section from 'ui-kit/section';

import Layout from '../components/layout';

const NotFoundPage = () => (
    <Layout>
        <Section bg="white" container="l">
            <h1>404 - Seite konnte leider nicht gefunden werden.</h1>
            <Link to="/">Bring mich zur Startseite</Link>
        </Section>
    </Layout>
);

export default NotFoundPage;
